<template>
  <div class="overflow-auto scroll scroll-pull" data-scroll="true" data-wheel-propagation="true">
    <b-table
      id="kundenTable"
      :items="kunden"
      :per-page="perPage"
      :current-page="currentPage"
      :fields="fields"
      :hidden="count === 0"
      primary-key="id"
      :outlined="isOutlined"
      details-td-class="p-0"
      :sort-desc="sortBy === 'desc'"
      :sort-by="sortBy"
      :sort-direction="sortDirection"
      :empty-filtered-text="
        $t('COMMON.EMPTYFILTEREDTEXT', {
          name: 'Kunden',
        })
      "
      :empty-text="
        $t('COMMON.EMPTYTEXT', {
          name: 'Kunden',
        })
      "
      show-empty
      thead-tr-class="border-bottom"
      :busy.sync="isLoading"
      table-class="table table-standard mb-0"
      @sort-changed="onSortChanged"
    >
      <template #row-details="row"> <slot></slot></template>
      <template v-slot:cell(actions)="row">
        <b-button
          @click="onUnmergeClick($event, row)"
          variant="secondary"
          :aria-label="$t('KUNDEN.UNMERGE')"
          :title="$t('KUNDEN.UNMERGE')"
        >
          {{ $t('KUNDEN.UNMERGE') }}
        </b-button>
      </template>
    </b-table>
    <SKRPagination
      v-if="isPaginationShowing"
      v-model="currentPage"
      :isLoading="isLoading"
      :totalRows="count"
      :currentRows="kunden.length || 0"
      :perPageOverwrite="perPageOverwrite"
      @pageChange="onPageChange"
    ></SKRPagination>
  </div>
</template>

<script>
import SKRPagination from '@/components/common/skr-pagination.vue';
export default {
  name: 'KundenTable',
  components: {
    SKRPagination,
  },
  props: {
    perPageOverwrite: { type: Number, required: false },
    kunden: { type: Array },
    count: { type: Number, default: null },
    isOutlined: { type: Boolean, default: false },
    isPaginationShowing: { type: Boolean, default: false },
    sortDirection: { type: String, default: 'asc' },
    sortBy: { type: String, default: 'id' },
    isLoading: { type: Boolean },
  },
  data() {
    return {
      perPage: null,
      currentPage: null,
      fields: [
        { key: 'id', label: this.$t('COMMON.ID') },
        { key: 'email.value', label: this.$t('AUTH.INPUT.EMAIL') },
        { key: 'telefonGeschaeftlich.value', label: this.$t('COMMON.TELGESCHAEFTLICHSHORT') },
        { key: 'telefonPrivat.value', label: this.$t('COMMON.TELEFONPRIVATSHORT') },
        { key: 'telefonMobil.value', label: this.$t('COMMON.TELMOBILSHORT') },
        { key: 'email.value', label: this.$t('AUTH.INPUT.EMAIL') },
        { key: 'kommentar', label: this.$t('REISEN.COMMON.COMMENT') },
        { key: 'email.value', label: this.$t('AUTH.INPUT.EMAIL') },
        { key: 'actions', label: this.$t('REISEN.COMMON.ACTIONS') },
      ],
    };
  },
  computed: {},
  methods: {
    onUnmergeClick($event, row) {
      this.$emit('onUnmergeClick', row.item);
    },
    onSortChanged($event) {
      this.$emit('sort-changed', $event);
    },
    onPageChange($event) {
      this.$emit('page-changed', $event);
    },
  },
};
</script>

<style lang="scss" scoped></style>
