<template>
  <div class="card card-custom">
    <div class="card-header">
      <h3 class="card-title font-weight-bolder" v-t="'REISEN.COMMON.VORGAENGE'"></h3>
      <div class="card-toolbar"></div>
    </div>
    <div class="card-body">
      <VorgangTable
        v-if="vorgaenge"
        :items="vorgaenge"
        :count="count"
        :isPaginationShowing="true"
        :isLoading="isLoading"
        :perPageOverwrite="top"
        :displayFieldsByKey="[
          'id',
          'reisezeitraum',
          'kunde',
          'zimmer',
          'npx',
          'agentur.id',
          'brutto',
          'stateSlot',
          'isReleased',
          'actions',
        ]"
        @sort-changed="onSortChanged"
        @page-changed="onPageChanged"
      >
        <template #stateSlot="row">
          <VorgangStateWidget :state="row.item.blankState" />
        </template>
      </VorgangTable>

      <b-skeleton-table v-if="vorgaenge.length === 0 && isLoading" :rows="8" :columns="6"></b-skeleton-table>
      <LoadSpinnerWidget
        v-if="!isLoading"
        :isLoading="isLoading"
        :dataAvailable="vorgaenge.length > 0"
      ></LoadSpinnerWidget>
    </div>
  </div>
</template>

<script>
import { VORGAENGE_SORT_CHANGED, VORGAENGE_PAGE_CHANGED } from '@/core/vorgaenge/stores/vorgaenge.module';
import VorgangTable from '@/components/vorgaenge/vorgang-table.vue';
import VorgangStateWidget from '@/components/vorgaenge/vorgang-state-widget.vue';

import LoadSpinnerWidget from '@/components/common/load-spinner-widget.vue';
import { mapState } from 'vuex';

export default {
  components: { VorgangTable, LoadSpinnerWidget, VorgangStateWidget },
  props: {},
  computed: {
    ...mapState({
      isLoading: state => state.vorgaenge.isLoading,
      vorgaenge: state => state.vorgaenge.vorgaenge,
      count: state => state.vorgaenge.count,
      top: state => state.vorgaenge.top,
    }),
  },
  methods: {
    onPageChanged(ctx) {
      this.$store.dispatch(VORGAENGE_PAGE_CHANGED, { pageContext: ctx });
    },
    onSortChanged(ctx) {
      if (ctx.sortBy === 'reisetermine.length') {
        ctx.sortBy = 'reisetermine/$count';
      }
      this.$store.dispatch(VORGAENGE_SORT_CHANGED, { sortContext: ctx });
    },
  },
};
</script>
