<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-12 gutter-b">
            <KundenViewCardVorgaenge></KundenViewCardVorgaenge>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 gutter-b">
            <KundenViewCardKunden></KundenViewCardKunden>
          </div>
        </div>
      </div>
      <div class="col-md-12"></div>

      <div class="col-md-12 gutter-b"></div>
      <div class="col-md-12">
        <div class="row"></div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.more-btn {
  cursor: pointer;
}
</style>

<script>
import KundenViewCardVorgaenge from '@/components/kunden/kunden-view-card-vorgaenge.vue';
import KundenViewCardKunden from '@/components/kunden/kunden-view-card-kunden.vue';
export default {
  name: 'KundenViewComponent',
  components: { KundenViewCardVorgaenge, KundenViewCardKunden },
  props: {},
};
</script>
