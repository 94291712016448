var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card card-custom"},[_c('div',{staticClass:"card-header"},[_c('h3',{directives:[{name:"t",rawName:"v-t",value:('REISEN.COMMON.VORGAENGE'),expression:"'REISEN.COMMON.VORGAENGE'"}],staticClass:"card-title font-weight-bolder"}),_c('div',{staticClass:"card-toolbar"})]),_c('div',{staticClass:"card-body"},[(_vm.vorgaenge)?_c('VorgangTable',{attrs:{"items":_vm.vorgaenge,"count":_vm.count,"isPaginationShowing":true,"isLoading":_vm.isLoading,"perPageOverwrite":_vm.top,"displayFieldsByKey":[
        'id',
        'reisezeitraum',
        'kunde',
        'zimmer',
        'npx',
        'agentur.id',
        'brutto',
        'stateSlot',
        'isReleased',
        'actions',
      ]},on:{"sort-changed":_vm.onSortChanged,"page-changed":_vm.onPageChanged},scopedSlots:_vm._u([{key:"stateSlot",fn:function(row){return [_c('VorgangStateWidget',{attrs:{"state":row.item.blankState}})]}}],null,false,1962827163)}):_vm._e(),(_vm.vorgaenge.length === 0 && _vm.isLoading)?_c('b-skeleton-table',{attrs:{"rows":8,"columns":6}}):_vm._e(),(!_vm.isLoading)?_c('LoadSpinnerWidget',{attrs:{"isLoading":_vm.isLoading,"dataAvailable":_vm.vorgaenge.length > 0}}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }