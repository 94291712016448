<template>
  <div class="card card-custom">
    <div class="card-header">
      <h3 class="card-title font-weight-bolder">{{ $t('COMMON.KUNDEN') }}</h3>
      <div class="card-toolbar"></div>
    </div>
    <div class="card-body">
      <KundenTable
        v-if="kunde"
        :kunden="kunde.kunden"
        :isLoading="isLoading"
        :count="10"
        @onUnmergeClick="onUnmergeClick"
      />
      <b-skeleton-table v-if="isInitialLoading" :rows="8" :columns="6"></b-skeleton-table>
      <LoadSpinnerWidget
        v-if="!isLoading"
        :isLoading="isLoading"
        :dataAvailable="kunde && kunde.kunden ? true : false"
      ></LoadSpinnerWidget>
    </div>
  </div>
</template>

<script>
import KundenTable from '@/components/kunden/kunden-table.vue';
import LoadSpinnerWidget from '@/components/common/load-spinner-widget.vue';
import { UNMERGE_KUNDE } from '@/core/kunden/stores/kunden.module';

import { mapState } from 'vuex';

export default {
  components: { KundenTable, LoadSpinnerWidget },
  props: {
    kunden: {
      type: Array,
    },
  },
  computed: {
    ...mapState({
      isLoading: state => state.kunden.isLoading,
      isInitialLoading: state => state.kunden.isInitialLoading,
      kunde: state => state.kunden.kunden[0],
    }),
  },
  methods: {
    onUnmergeClick(kunde) {
      this.$store.dispatch(UNMERGE_KUNDE, kunde.id).then(() => {
        this.toast(this.$t('WORKFLOWS.COMMON.SAVED'));
      });
    },
  },
};
</script>
